<template>
    <div class="residentdatabase-my-business">
        <CompTable ref="comp_table" title="健康指导设置" :columns="columns" :formComponent="formComponent" :table-api="'/gateway/api/synursing/pc/cat/selectByPage?orgCode=' + orgCode"></CompTable>
        <!-- 分类详情 -->
        <CatDetail ref="cat_detail"></CatDetail>
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import FormComponent from "./form.vue"
import CatDetail from "./detail.vue"

export default {
    components: { CompTable, CatDetail },

    data() {
        return {
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
            formComponent: FormComponent,

            columns: [
                {
                    title: "分类名称",
                    key: "catName",
                    align: "center",
                },
                {
                    title: "排序",
                    key: "sort",
                    align: "center",
                },
                {
                    float: "right",
                    title: "操作",
                    maxWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.comp_table.openForm(params.row.catId, params.row)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "default",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.cat_detail.open(params.row.catId)
                                        },
                                    },
                                },
                                "明细"
                            ),
                        ])
                    },
                },
            ],
        }
    },
}
</script>
